// import React from "react";

// import FooterLogo from "../assets/images/logo-1-1.png";
// import qrCodeImage from '../assets/images/qr1.jpg';
// const Footer = () => {
//   return (

// <div
//   className="site-footer1"
//   style={{
//     paddingTop: '60px',
//     // background: 'linear-gradient(90deg, #5B307D, #A475B9)', // Gradient background
//     background: 'linear-gradient(343deg, #f5eff7, 0%, #ae78e7 100%)',
//     color: '#fff',
//     height: '400px',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//   }}
// >
//   <div className="container1">
//     <div className="row justify-content-between align-items-center">
//       {/* Left Side: Logo */}
//       <div className="col-lg-3 mb-4 d-flex justify-content-center">
//         <img src={FooterLogo} alt="Logo" style={{ maxWidth: '150px' }} />
//       </div>

//       {/* Center: Description Text */}
//       <div className="col-lg-5 mb-4 text-center">
//         <p>
//           Chào mừng bạn đến với iudi, ứng dụng hẹn hò duy nhất tại thời điểm này ở
//           Việt Nam hoạt động trên nền tảng Zalo.
//         </p>
//       </div>

//       {/* Right Side: App Download Links */}
//       <div className="col-lg-3 mb-4 text-center">
//         <p>Tải App ngay</p>
//         <div className="d-flex justify-content-center">
//         <img
//       src={qrCodeImage}
//       alt="QR Code"
//       style={{ width: '150px', height: '150px', objectFit: 'contain' }}
//     />
//         </div>
//       </div>
//     </div>
//   </div>

//   {/* Footer Bottom */}
//   <div className="text-center mt-2" style={{ marginTop: 'auto' }}>
//     <div className="d-flex justify-content-center mb-3">
//       {/* Social Media Icons */}
//       <a
//         href="#none"
//         className="btn"
//          style={{
//         backgroundColor: '#5B307D',
//         color: '#1877F2', // Facebook blue color
//         borderRadius: '50%',
//         width: '40px',
//         height: '40px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         marginRight: '10px',
//       }}
//       >
//         <i className="bi bi-facebook"></i>
//       </a>
//       <a
//         href="#none"
//         className="btn"
//         style={{
//           backgroundColor: '#5B307D',
//           color: '#FF0000', // Facebook blue color
//           borderRadius: '50%',
//           width: '40px',
//           height: '40px',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           marginRight: '10px',
//         }}
//       >
//         <i className="bi bi-youtube"></i>
//       </a>
//     </div>
//     <p>© 2024 - Nối Group</p>
//   </div>
// </div>



//   );
// };

// export default Footer;


import React from "react";

import FooterLogo from "../assets/images/logo-1-1.png";
import qrCodeImage from '../assets/images/qr1.jpg';

const Footer = () => {
  return (
    <div
      className="site-footer1"
      style={{
        paddingTop: '60px',
        paddingBottom: '20px', // Add padding to bottom
        background: 'linear-gradient(343deg, #f5eff7, 0%, #ae78e7 100%)',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div className="container">
        <div className="row justify-content-between align-items-center">
          {/* Left Side: Logo */}
          <div className="col-lg-3 col-md-4 col-12 mb-4 d-flex justify-content-center">
            <img src={FooterLogo} alt="Logo" style={{ maxWidth: '150px' }} />
          </div>

          {/* Center: Description Text */}
          <div className="col-lg-5 col-md-4 col-12 mb-4 text-center">
  <div style={{ textAlign: 'left', lineHeight: '1.2' }}>
    <p>VỀ CHÚNG TÔI</p>
    <p>• Công Ty TNHH Nối Group</p>
    <p>• MST: 0318379051</p>
    <p>• Tầng 08, Toà nhà Pearl Plaza, Số 561A Điện Biên Phủ, P.25, Q.Bình Thạnh, TP.HCM</p>
    <p>• Tel: 0866428869</p>
    <p>• Website: www.noi.dating</p>
  </div>
</div>

          {/* Right Side: App Download Links */}
          <div className="col-lg-3 col-md-4 col-12 mb-4 text-center">
            <p>Tải App ngay</p>
            <div className="d-flex justify-content-center">
              <img
                src={qrCodeImage}
                alt="QR Code"
                style={{ width: '150px', height: '150px', objectFit: 'contain' }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="text-center mt-2" style={{ marginTop: 'auto' }}>
        <div className="d-flex justify-content-center mb-3">
          {/* Social Media Icons */}
          <a
            href="#none"
            className="btn"
            style={{
              backgroundColor: '#5B307D',
              color: '#1877F2',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10px',
            }}
          >
            <i className="bi bi-facebook"></i>
          </a>
          <a
            href="#none"
            className="btn"
            style={{
              backgroundColor: '#5B307D',
              color: '#FF0000',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10px',
            }}
          >
            <i className="bi bi-youtube"></i>
          </a>
        </div>
        <p style={{ marginBottom: '0' }}>© 2024 - Nối Group</p>
      </div>
    </div>
  );
};

export default Footer;
